import {Box, Button, Grid, Paper} from "@mui/material";
import {makeStyles} from '@mui/styles'
import {FirstName, Password, Username, LastName, ClinicName, ProviderEmail} from "./fields";
import {useState} from "react";
import {LoginAuthSchema, validate} from "../../models/auth";
import {signUp, signIn} from 'aws-amplify/auth'
import {useNavigate, useSearchParams} from "react-router-dom";
import BannerBar, {variant} from "../messaging/banner";
import {useLoading} from "../../hooks/loading";
import {CreateClinicSchema} from "../../models/clinic";
import {InviteProviderSchema} from "../../models/providers";

const useStyles = makeStyles(() => ({
  grid: {
    height: "100%"
  },
  acceptInvite: {
    padding: 10,
    width: "40%"
  }
}));

export function Register() {
  const nav = useNavigate()
  const styles = useStyles();
  const [form, setForm] = useState<{ [key: string]: string }>({})
  const [error, setError] = useState<{ [key: string]: string }>({})
  const [usernameSuccess, setUsernameSuccess] = useState<boolean>(false);

  function setUsername(username: string) {
    setForm({...form, username})
  }

  function setPassword(password: string) {
    setForm({...form, password})
  }

  function setConfirmPassword(confirmPassword: string) {
    setForm({...form, confirmPassword})
  }

  function v() {
    setError(validate(LoginAuthSchema, form))
  }

  async function Submit() {
    v();
    if (Object.keys(error).length === 0) {
      await signUp({
        username: form.username,
        password: form.password,
        options: {
          userAttributes: {
            email: form.username,
          }
        }
      })
      nav("/confirm")
    }
  }

  function UsernameFocus() {
    v();
    if (!error["username"] && !usernameSuccess) {
      setUsernameSuccess(true)
    }
  }

  function PasswordFocus() {
    v();
  }

  return (
    <Box height="50vh" mr={4}>
      <Grid
        className={styles.grid}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        spacing={2}
      >
        <Grid item md={4}>
          <Box sx={{display: "flex", flexDirection: "column", marginBottom: 1, justifyContent: "center"}}>
            <Username success={!error["username"] && usernameSuccess} onBlur={UsernameFocus}
                      callback={setUsername}
                      error={!!error["username"]} msg={error["username"]} onFocus={UsernameFocus}/>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: 1}}>
            <Password onBlur={PasswordFocus} onFocus={PasswordFocus} callback={setPassword}
                      error={!!error["password"]}/>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: 1}}>
            <Password callback={setConfirmPassword} confirm={true} error={!!error["confirmPassword"]}/>
          </Box>
          <Grid container>
            <Grid item sm={12} md={6}>
              <Button variant="contained" onClick={Submit}>
                Register
              </Button>
            </Grid>
            <Grid item sm={12} md={6} sx={{alignContent: "center"}}>
              <Box sx={{textAlign: "right"}}>
                <a style={{color: "blue", cursor: "pointer"}} onClick={() => nav("/")}>Login</a>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export function Login() {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirect] = useState(searchParams.get("redirect") || "")

  const [form, setForm] = useState<{ [key: string]: string }>({
    username: searchParams.get("email")!
  })
  const nav = useNavigate()
  const [errors, setError] = useState<{ [key: string]: string }>({})
  const {setLoading} = useLoading()

  function setUsername(username: string) {
    setForm({...form, username})
  }

  function v() {
    setError(validate(LoginAuthSchema, form))
  }

  function setPassword(password: string) {
    setForm({...form, password})
  }

  async function Submit() {
    setLoading(true)
    v();
    if (Object.keys(errors).length > 0) {
      return
    }

    const user = await signIn({
      username: form.username,
      password: form.password,
    })

    setLoading(false)

    if (user.nextStep.signInStep === "CONFIRM_SIGN_UP") {
      nav("/confirm")
      setSearchParams({username: form.username})
      return
    }

    if (redirect === "") nav("/dashboard")
    else nav(redirect)

  }

  async function Login() {
    try {
      await Submit();
    } catch (error) {
      console.log(error)
      return
    }
  }

  async function Keydown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    try {
      if (event.key === "Enter") {
        await Submit();
      }
    } catch (error) {
      console.log(error)
      return
    }

  }

  return (
    <BannerBar message="Your Account Has Been Confirmed, Please Login." v={variant.success}>
      <Box height="50vh" mr={4}>
        <Grid
          className={styles.grid}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          spacing={2}
        >
          <Grid item md={4}>
            <Box sx={{display: "flex", flexDirection: "column", marginBottom: 1, justifyContent: "center"}}>
              <Username callback={setUsername} error={!!errors["username"]} keypress={Keydown}
                        defaultValue={form.username}/>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: 1}}>
              <Password callback={setPassword} error={!!errors["password"]} keypress={Keydown}/>
            </Box>
            <Grid container>
              <Grid item sm={12} md={6}>
                <Button variant="contained" onClick={Login}>
                  Login
                </Button>
              </Grid>
              <Grid item sm={12} md={6} sx={{alignContent: "center"}}>
                <Box sx={{textAlign: "right"}}>
                  <a style={{color: "blue", cursor: "pointer"}} onClick={() => nav("/register")}>Create
                    Account</a>
                </Box>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Box>
    </BannerBar>
  )
}

type CreateUserForm = {
  firstName: string,
  lastName: string
} & { [key: string]: any }

type CreateUserProps = {
  setForm: (form: CreateUserForm) => void
  form: CreateUserForm
  errors: { [key: string]: string }
}

export function CreateUser({form, setForm, errors}: CreateUserProps) {

  function onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    form[e.currentTarget.name] = e.currentTarget.value
    setForm({...form})
  }

  return (
    <>
      <Box>
        <FirstName onChange={onChange} error={!!errors["firstName"]}/>
      </Box>
      <Box sx={{marginTop: 1.5}}>
        <LastName onChange={onChange} error={!!errors["lastName"]}/>
      </Box>
    </>
  )
}

export type ClinicForm = {
  name: string
}

export type InviteProviderForm = {
  email: string
}

type CreateClinicProps = {
  Submit: (form: ClinicForm) => Promise<void>;
  form: ClinicForm,
  setForm: (form: ClinicForm) => void
}

type InviteProviderProps = {
  form: InviteProviderForm
  setForm: (form: InviteProviderForm) => void
  Submit: (form: InviteProviderForm) => Promise<void>;
  passedErrors?: { [key: string]: string }
}

export type AcceptInviteForm = {
  first_name: string,
  last_name: string,
  pass: string,
  confirmPassword: string,
}

type AcceptInviteProps = {
  clinic_name: string,
  email: string,
  Submit: (form: AcceptInviteForm) => Promise<void>,
  setForm: (form: AcceptInviteForm) => void,
  form: AcceptInviteForm,
  errors?: { [key: string]: string },
  setErrors?: (errors: { [key: string]: string }) => void
}

export function AcceptInvite({setForm, form, setErrors, Submit, errors, clinic_name, email}: AcceptInviteProps) {
  const style = useStyles();

  function setFirstName(first_name: string) {
    setForm({...form, first_name});
  }

  function setLastName(last_name: string) {
    setForm({...form, last_name});
  }

  function setPassword(pass: string) {
    setForm({...form, pass})
  }

  function setConfirmPassword(pass: string) {
    setForm({...form, confirmPassword: pass})
  }

  async function submit() {
//    const errors = validate(InviteProviderSchema, form);
//    if (Object.keys(errors).length > 0 && setErrors) {
//      setErrors(errors);
//      return
//    }

    await Submit(form);
  }

  return (
    <Box height="45vh" mr={4} component={Paper} className={style.acceptInvite}>
      <h3>Create your profile for clinic: {clinic_name}</h3>
      <h3>For email: {email}</h3>
      <Box>
        <FirstName onChange={(e) => setFirstName(e.currentTarget.value)} error={!!errors!["first_name"]}/>
      </Box>
      <Box style={{marginTop: 10}}>
        <LastName onChange={(e) => setLastName(e.currentTarget.value)} error={!!errors!["last_name"]}/>
      </Box>
      <Box style={{marginTop: 10}}>
        <Password callback={setPassword}/>
      </Box>
      <Box style={{marginTop: 10}}>
        <Password callback={setConfirmPassword} confirm={true}/>
      </Box>
      <Box style={{marginTop: 10}}>
        <Button variant="contained" onClick={() => Submit(form)}>
          Join Clinic
        </Button>
      </Box>
    </Box>
  )
}

export function InviteProvider({Submit, form, setForm, passedErrors}: InviteProviderProps) {
  const [errors, setError] = useState<{ [key: string]: string }>({});

  function handleChange(email: string) {
    console.log(email);
    setForm({...form, email} as InviteProviderForm)
  }

  async function submit() {
    const errors = validate(InviteProviderSchema, form);
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return
    }

    await Submit(form);
  }

  async function Keydown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    try {
      if (event.key === "Enter") {
        await submit();
      }
    } catch (error) {
      console.log(error)
      return
    }
  }

  console.log(passedErrors && !!passedErrors["email"], passedErrors, "does it exist");
  return (
    <Box>
      <ProviderEmail callback={handleChange}
                     error={(passedErrors && !!passedErrors["email"]) || !!errors["email"]}
                     keypress={Keydown}/>
    </Box>
  )
}

export function CreateClinic({Submit, form, setForm}: CreateClinicProps) {
  const [errors, setError] = useState<{ [key: string]: string }>({});

  function handleChange(name: string) {
    console.log(name);
    setForm({...form, name} as ClinicForm)
  }

  async function submit() {
    console.log(form)
    const errors = validate(CreateClinicSchema, form);
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return
    }

    await Submit(form);
  }

  async function Keydown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    try {
      if (event.key === "Enter") {
        await submit();
      }
    } catch (error) {
      console.log(error)
      return
    }
  }

  return (
    <Box>
      <ClinicName callback={handleChange} error={!!errors["name"]} keypress={Keydown}/>
    </Box>
  )
}