import {createContext, useContext} from "react";
import Fetch from "../tools/fetch";
import {HTTP_STATUS} from "../tools/enums";

type RequestContext = {
  host: string,
  proto: string,
  get: <D, T extends {
    status: number;
    data: D;
  }>(token: string, path: string, checking?: boolean | undefined, body?: boolean | undefined) => Promise<T>
  post: <D, T extends {
    status: number;
    data: D;
  }>(token: string, path: string, payload: any, checking?: boolean | undefined, body?: boolean | undefined) => Promise<T>,
  delete: (token: string, path: string, checking?: boolean | undefined) => Promise<{ status: number }>,
}

const RequestDefaults = {
  host: "api.phq365.com",
  proto: "https://",
  get: <D, T extends {
    status: number;
    data: D;
  }>(token: string, path: string, checking?: boolean | undefined, body?: boolean | undefined): Promise<T> => {
    return new Promise((resolve) => {

    });
  },
  post: <D, T extends {
    status: number;
    data: D;
  }>(token: string, path: string, payload: any, checking?: boolean | undefined, body?: boolean | undefined): Promise<T> => {
    return new Promise((resolve) => {

    });
  },
  delete: (token: string, path: string, checking?: boolean | undefined): Promise<{ status: number }> => {
    return new Promise((resolve) => {

    })
  }
}

export const RequestCtx = createContext<RequestContext>(RequestDefaults)

export const ProvideRequest = ({children}: { children: JSX.Element }) => {
  const ctx = Provider();
  return <RequestCtx.Provider value={ctx}>{children}</RequestCtx.Provider>
}

export const useRequest = () => {
  return useContext(RequestCtx);
}

type standard_response = {
  status: number;
  data: any
}

type check_token_response = {} & standard_response;

const Provider = () => {

  let req = new Fetch(`${RequestDefaults.proto}${RequestDefaults.host}`, 443);

  async function check_token(token: string): Promise<number> {
    const resp = await get<any, check_token_response>(token, "admin/protected/verify-token", false, false);
    return resp.status
  }

  async function post<D, T extends {
    status: number,
    data: D
  }>(token: string, path: string, payload: any, checking: boolean = true, body: boolean | undefined): Promise<T> {
    if (checking) {
      const ok = await check_token(token);
      if (ok !== HTTP_STATUS.ACCEPTED) {
        return {} as T;
      } else {
//                need a call to refresh token here
      }
    }

    const res = await req.New().Post().Path(path).Token(token).Body(payload).Receive<{
      status: number,
      data: D
    }, any>(body)
    return res;
  }

  async function del(token: string, path: string, checking: boolean = true): Promise<{ status: number }> {
    if (checking) {
      const ok = await check_token(token);
      if (ok !== HTTP_STATUS.ACCEPTED) {
        return {status: 400};
      } else {
//                need a call to refresh token here
      }
    }

    return await req.New().Delete().Path(path).Token(token).Receive<{ status: number, data: {} }, any>(false);
  }

  async function get<D, T extends {
    status: number,
    data: D
  }>(token: string, path: string, checking: boolean = true, body: boolean = true): Promise<T> {
    if (checking) {
      const ok = await check_token(token);
      if (ok !== HTTP_STATUS.ACCEPTED) {
        return {} as T;
      } else {
//                need a call to refresh token here
      }
    }
    const res = await req.New().Get().Path(path).Token(token).Receive<{ status: number, data: D }, any>(body);
    return {status: res.status, data: await res.data} as T
  }

  return {
    ...RequestDefaults,
    get,
    post,
    delete: del,
  }
}