import {createContext, useContext, useState} from "react";
import Fetch from "../tools/fetch";
import {HTTP_STATUS} from "../tools/enums";
import {useRequest} from "./requests";

type ClinicData = {
    name: string
}

type Clinic = {
    id: string,
    owner_id: string
} & ClinicData

type AllClinics = {
    manages: Clinic[]
    practices_at: Clinic[]
}

type ClinicContext = {
    clinics: AllClinics,
    createClinic: (newClinic: ClinicData, token: string) => Promise<void>
    getAllMyClinics: (token: string) => Promise<void>
}

export const ClinicCtx = createContext<ClinicContext>({
    clinics: {
        manages: [],
        practices_at: [],
    },
    createClinic: async (newClinic: ClinicData, token: string) => {
    },
    getAllMyClinics: async (token: string) => {
    }
})

export const ProvideClinicData = ({children}: { children: JSX.Element }) => {
    const ctx = Provider();
    return <ClinicCtx.Provider value={ctx}>{children}</ClinicCtx.Provider>
}

export const useClinicData = () => {
    return useContext(ClinicCtx);
}


const Provider = () => {
    const [clinics, setClinics] = useState<AllClinics>({
        manages: [],
        practices_at: [],
    })
    const {get} = useRequest()

    async function createClinic(newClinic: ClinicData, token: string) {
        const f = new Fetch("https://api.phq365.com", 443)
        const response = await f.Token(token).Post().Path("admin/clinics").Body(newClinic).Receive<Clinic>()

    }

    async function getAllMyClinics(token: string) {
        const response = await get<AllClinics, { status: number, data: AllClinics }>(token, "admin/clinics");
        setClinics(response.data)
    }

    return {
        clinics,
        createClinic,
        getAllMyClinics
    }
}