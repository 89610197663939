import { createContext, useContext, useState } from "react";

type BannerContext = {
    showBanner: false,
    setShowBanner: (banner: boolean) => void
}

export const ctx = createContext({
    showBanner: false,
    setShowBanner: (banner: boolean) =>{}
})

export const ProvideBanner = ({ children }: {children: JSX.Element}) => {
    const bannerCtx = Provider();
    return <ctx.Provider value={bannerCtx}>{children}</ctx.Provider>;
};

export const useBanner = () => {
    return useContext(ctx);
};

const Provider = () => {
    const [showBanner, setShowBanner] = useState(false)

    return {
        showBanner, 
        setShowBanner
    } as BannerContext
}