import {getCurrentUser, fetchAuthSession, signOut} from 'aws-amplify/auth'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useProvideUserData} from "../../hooks/users";
import {HTTP_STATUS} from "../../tools/enums";
import SimpleDialog, {CreateUserFormActions, CreateUserFormContent} from "../dialog";
import {validate} from "../../models/auth";
import {CreateProfileSchema} from "../../models/profile";
import {useLoading} from "../../hooks/loading";
import {useDialog} from "../../hooks/simpleDialog";

type LockdownProps = {
  children: JSX.Element
}

type CreateUserForm = {
  firstName: string,
  lastName: string
} & { [key: string]: any }

export function AuthCheck({children}: LockdownProps) {
  const nav = useNavigate();
  const {loading, setLoading} = useLoading()

  useEffect(() => {
    async function load() {
      setLoading(true)
      try {
        const tokens = await getCurrentUser()
        if (tokens) {
          nav("/dashboard");
        }
      } catch (e) {
        setLoading(false)
      }

      setLoading(false)
    }

    load();
  }, [])

  return (
    <>
      {children}
    </>
  )
}

export default function Lockdown({children}: LockdownProps) {
  const nav = useNavigate()
  const {getProfile, profile, createProfile} = useProvideUserData()
  const [form, setForm] = useState<CreateUserForm>({
    firstName: "",
    lastName: ""
  })
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const {open, setActions, setContent, setOpen, setTitle, title, content, actions} = useDialog()
  const {setLoading} = useLoading()
  const [render, setRender] = useState(false)

  async function SubmitForm() {
    const errors = validate(CreateProfileSchema, form)
    if (Object.keys(errors).length > 0) {
      setErrors(errors)
      setContent(<CreateUserFormContent errors={errors} form={form} setForm={setForm}/>)
      return
    } else {
      setErrors({})
      setContent(<CreateUserFormContent errors={errors} form={form} setForm={setForm}/>)
      const {tokens} = await fetchAuthSession();
      try {
        const response = await createProfile({
          first_name: form.firstName,
          last_name: form.lastName
        }, tokens!.accessToken.toString());
        if (!response) {
          alert("couldn't create user");
          console.log(profile)
        } else {
          setOpen(false);
        }
      } catch (error) {
        console.log(error)
      }


    }
  }

  // insert create call here

  useEffect(() => {
    async function load() {
      setTitle("Create your profile")
      try {
        const {tokens: t} = await fetchAuthSession()
        console.log(t?.accessToken.toString());
        const data = sessionStorage.getItem("profile")
        if (data && JSON.parse(data).first_name !== "") {
          setRender(true)
          console.log("this")
          return
        }
        const user = await getCurrentUser()
        console.log(user, "user");
        const {tokens} = await fetchAuthSession()
        if (!tokens) {
          throw new Error("not authenticated")
        }

        setLoading(true)
        console.log("this")
        await getProfile(tokens.accessToken.toString())

        if (profile.status === HTTP_STATUS.NOT_FOUND) {
          setOpen(true)
          setContent(<CreateUserFormContent errors={errors} form={form} setForm={setForm}/>)
          setActions([<CreateUserFormActions action={SubmitForm}/>])
        }

        if (profile.status === HTTP_STATUS.OK) {
          setOpen(false);
        }

        setLoading(false);
      } catch (error) {
        nav("/")
      }
    }

    load();
  }, [])

  useEffect(() => {
    async function load() {
      setTitle("Create your profile")
      try {
        const data = sessionStorage.getItem("profile")
        if (data && JSON.parse(data).first_name !== "") {
          setRender(true)
          return
        }
        const user = await getCurrentUser()
        const {tokens} = await fetchAuthSession()
        if (!tokens) {
          throw new Error("not authenticated")
        }
        setLoading(true)
        await getProfile(tokens.accessToken.toString())
        setLoading(false);
      } catch (error) {
        nav("/")
      }
    }

    if (!render) load()
  }, [render])


  useEffect(() => {
    console.log(profile.status)
    if (profile.status === HTTP_STATUS.OK) {
      sessionStorage.setItem("profile", JSON.stringify(profile))
      setRender(true)
      setLoading(false);
    } else if (profile.status === HTTP_STATUS.NOT_FOUND) {
      setOpen(true)
      setLoading(false);
      setRender(true)
      setContent(<CreateUserFormContent errors={errors} form={form} setForm={setForm}/>)
      setActions([<CreateUserFormActions action={SubmitForm}/>])
    }
  }, [profile.status])

  return (
    <>
      {render && <SimpleDialog title={title} content={content!} actions={actions} open={open}
                               setOpen={setOpen}>{children}</SimpleDialog>
      }
    </>
  )
}
