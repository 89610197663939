import {Login, Register} from './components/auth/form';
import {createTheme, ThemeProvider} from '@mui/material';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import {ConfirmCode} from './components/auth/mfa';
import {ProvideBanner} from './hooks/banner';
import Lockdown, {AuthCheck} from "./components/auth/lockdown";
import Dashboard from "./pages/dashboard";
import {ProvideUserData} from "./hooks/users";
import {ProvideLoading} from "./hooks/loading";
import Loading from "./components/loading";
import PricingPage from "./pages/pricing";
import Companies from "./pages/companies";
import {ProvideDialog} from "./hooks/simpleDialog";
import {ProvideClinicData} from "./hooks/clinics";
import {InviteChecker} from "./components/auth/inviteChecker";
import {ProvideRequest} from "./hooks/requests";
import {ProvideProviders} from "./hooks/providers";


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: "us-west-2_vWmfqzK4m",
      userPoolClientId: "5pof08ehq5bu864589dcbc4hog"
    }
  }
})

const theme = createTheme();

const router = createBrowserRouter([
  {
    path: "",
    element: <AuthCheck><Login/></AuthCheck>
  },
  {
    path: "register",
    element: <AuthCheck><Register/></AuthCheck>
  },
  {
    path: "confirm",
    element: <AuthCheck><ConfirmCode/></AuthCheck>
  },
  {
    path: "dashboard",
    element: <ProvideUserData><Lockdown><Dashboard/></Lockdown></ProvideUserData>
  },
  {
    path: "pricing",
    element: <ProvideUserData><Lockdown><PricingPage/></Lockdown></ProvideUserData>,
  },
  {
    path: "clinics",
    element:
      <ProvideProviders><ProvideUserData><ProvideClinicData><Lockdown><Companies/></Lockdown></ProvideClinicData></ProvideUserData></ProvideProviders>,
  },
  {
    path: "clinics/:clinic_id/join/:token",
    element: <ProvideUserData><InviteChecker/></ProvideUserData>,
  }
])


function App() {

  return (
    <ThemeProvider theme={theme}>
      <ProvideRequest>
        <ProvideDialog>
          <ProvideLoading>
            <Loading>
              <ProvideBanner>
                <div className="App">
                  <RouterProvider router={router}/>
                </div>
              </ProvideBanner>
            </Loading>
          </ProvideLoading>
        </ProvideDialog>
      </ProvideRequest>
    </ThemeProvider>
  );
}

export default App;
