import { FormControl, Grid, Input } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AuthError, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const styles = makeStyles({
    mfaInput: {
        paddingLeft: "40%", 
        fontSize: 30
    }
})

type MfaProps = {
    length?: number; 
}

export function ConfirmCode({length = 6} : MfaProps) {
    const classes = styles(); 
    const nav = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    let username = searchParams.get("username")
    if (username) {
        username = decodeURIComponent(username)
    }

    const [u] = useState(username)
    
    const els: JSX.Element[] = []; 
    const [code, setCode] = useState(new Array(6))
    const [inputs] = useState([useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null),useRef<HTMLInputElement>(null),useRef<HTMLInputElement>(null),useRef<HTMLInputElement>(null),useRef<HTMLInputElement>(null)])
    const [error, setError] = useState(false); 
    async function fireConfirm(code: string) {
        if (!u) {
            return 
        }
        try {
            const resp = await confirmSignUp({
                username: u,
                confirmationCode: code, 
            })
            setSearchParams({confirm: "true"})
            nav("/")
        } catch(error) {
            setError(true); 
        }
        
        
    }

    useEffect(() => {
       if (code.join("").length === 6) {
            fireConfirm(code.join(""))
       } else {
        setError(false)
       }
    }, [code])

    async function onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const index = parseInt(event.currentTarget.name)
        const val = event.currentTarget.value; 

        if (val.length > 1 || (isNaN(parseInt(val)) && val != "")) {
            return
        }

        code[index] = val
        setCode([...code])
        if(val != "" && index < code.length - 1) {
            const input = inputs[index + 1].current?.firstChild as HTMLInputElement
            input.focus()
        }

        if (val == "" && index > 0) {
            const input = inputs[index - 1].current?.firstChild as HTMLInputElement
            input.focus()
        }
    }
    

    function Keydown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (event.code === "Backspace") {
            const val = event.currentTarget.value
            const index = parseInt(event.currentTarget.name)
            code[index] = val 
            setCode([...code])
            if (val == "" && index > 0) {
                const input = inputs[index - 1].current?.firstChild as HTMLInputElement
                input.focus()
                return 
            }
            console.log(index)
        }
    }

    async function GetNewCode() {
        console.log(u, "username")
        if (u) {
            const resp = await resendSignUpCode({
                username: u, 
            })
            alert("confirmation code sent")
        }
    }

    for (let index = 0; index < length; index++) {
        els.push((
            <Grid key={index} item sm={2}>
                <Input error={error} onKeyDown={Keydown} ref={inputs[index]} value={code[index]} onChange={(event) => onChange(event)} name={index.toString()} className={classes.mfaInput} />
            </Grid>
        ))
    }

    return (
        <>
            {/* make this a variable */}
            <h2 style={{textAlign: "center", marginBottom: 100}}>Confirm your account</h2>
            <Grid container spacing={2}>
                <Grid item sm={4}></Grid>
                <Grid item sm={4}>
                    <Grid container spacing={2}>
                        {els}
                    </Grid>
                    <Grid container spacing={2} sx={{marginTop: 1}}>
                        <Grid item sm={12}>
                            <a style={{color: "blue", cursor: "pointer"}} onClick={() => GetNewCode()}>Get new code?</a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}