import { Box } from "@mui/material";
import { useBanner } from "../../hooks/banner"
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

export enum variant {
    success = "success"
}

type BannerProps = {
    message: string, 
    children: JSX.Element, 
    v: variant
}

export default function BannerBar({children, message, v}: BannerProps) {
    const { showBanner, setShowBanner } = useBanner();
    const [searchParams] = useSearchParams(); 

    useEffect(() => {
        if (searchParams.get("confirm") && !!searchParams.get("confirm")) setShowBanner(true)
    }, [searchParams.get("confirm")])


    function CloseBanner() {
        setShowBanner(false); 
    }

    let bg = ""; 
    switch(v) {
        case variant.success: 
            bg = "green"
        break; 
    }

    return (
        <>  
            <Box sx={{alignItems: "center", justifyContent: "center", display : showBanner ? "flex" : "none", padding: 2, fontSize: 15, textAlign: "center", color: "white", width: "100%", backgroundColor: bg}}>
                <Box>
                    <span>{message}</span>
                </Box>
                <div style={{position: "absolute", right: 10, cursor: "pointer"}} onClick={CloseBanner}>
                    <CloseIcon fontSize="small" />
                </div>
            </Box>
            {children}
        </>
    )
}