import {createContext, useContext, useState} from "react";

type DialogContext = {
  open: boolean,
  setOpen: (open: boolean) => void,
  content: JSX.Element | undefined,
  setContent: (content: JSX.Element) => void,
  actions: JSX.Element[],
  setActions: (actions: JSX.Element[]) => void,
  title: string,
  setTitle: (title: string) => void,
}

export const DialogCtx = createContext<DialogContext>({
  open: false,
  setOpen: (open: boolean) => {
  },
  content: undefined,
  setContent: (content: JSX.Element) => {
  },
  actions: [],
  setActions: (actions: JSX.Element[]) => {
  },
  title: "",
  setTitle: (title: string) => {
  }
})

export const ProvideDialog = ({children}: { children: JSX.Element }) => {
  const ctx = Provider();
  return <DialogCtx.Provider value={ctx}>{children}</DialogCtx.Provider>
}

export const useDialog = () => {
  return useContext(DialogCtx);
}

const Provider = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [content, setContent] = useState<JSX.Element>();
  const [actions, setActions] = useState<JSX.Element[]>([]);
  const [title, setTitle] = useState<string>("");
  return {
    open,
    setOpen,
    content,
    setActions,
    setContent,
    actions,
    title,
    setTitle
  }
}