enum Methods {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  OPTIONS = "OPTIONS"
}

export default class Fetch {
  private host: string = "";
  private port: number = 0;
  private path: string = "";
  private method: string = "";
  private body: string = ""
  private headers: Headers = new Headers()

  constructor(host: string, port: number) {
    this.host = host;
    this.port = port;
  }

  public New(): Fetch {
    return new Fetch(this.host, this.port)
  }

  public Path(path: string): Fetch {
    this.path = path;
    return this;
  }

  private Method(method: Methods): Fetch {
    this.method = method;
    return this
  }

  public Get(): Fetch {
    return this.Method(Methods.GET);
  }

  public Delete(): Fetch {
    return this.Method(Methods.DELETE)
  }

  public Post(): Fetch {
    return this.Method(Methods.POST)
  }

  public Body(b: any): Fetch {
    this.body = JSON.stringify(b);
    return this
  }

  public Token(token: string): Fetch {
    this.headers.append("Authorization", `Bearer ${token}`);
    return this;
  }

  public async Receive<D, E = {
    msg: { [key: string]: any },
    status: number
  }>(has_body: boolean = true): Promise<{ data: D, status: number } | E> {

    this.headers.set("Content-Type", "application/json");

    const response = await fetch(`${this.host}/${this.path}`, {
      method: this.method,
      headers: this.headers,
      body: (this.method === Methods.GET || this.method === Methods.OPTIONS) ? null : this.body,
    })

    if (!has_body) {
      return {data: {} as D, status: response.status}
    }

    if (response.ok) {

      const payload = await response.json()

      if (Array.isArray(payload)) {
        return {
          data: payload as D,
          status: response.status
        }
      }

      return {data: payload as D, status: response.status};
    }

    return {
      "msg": await response.json(),
      "status": response.status
    } as E;
  }

}