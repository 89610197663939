import {createContext, useContext, useState} from "react";
import Fetch from "../tools/fetch";
import {HTTP_STATUS} from "../tools/enums";

type Tier = {
  id: string,
  name: string,
  default_price: {
    unit_amount: number,
  }
}


type PricingPage = {
  tiers: Tier[],
  getTiers: (token: string) => Promise<void>
}

type PricingStatus = {
  status: number,
}

type PricingTiers = {
  data: Tier[]
} & PricingStatus

export const UserContext = createContext<PricingPage>({
  tiers: [],
  getTiers: async (token: string) => {
  },
})

export const ProvideTiers = ({children}: { children: JSX.Element }) => {
  const ctx = Provider();
  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>
}

export const useProvideTiers = () => {
  return useContext(UserContext);
}

const Provider = () => {
  const [tiers, setTiers] = useState<Tier[]>([]);


  async function getTiers(token: string) {
    const response = await new Fetch("https://api.phq365.com", 443).Token(token).Get().Path("admin/products").Receive<Tier[]>();
    if ((response as PricingStatus).status === HTTP_STATUS.OK) {
      const tiers = response as PricingTiers;
      setTiers([...tiers.data])
    } else {
      console.log("show error page for pricing")
    }
  }

  return {
    tiers,
    getTiers
  }
}