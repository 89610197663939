import {useNavigate} from "react-router-dom";
import {Typography} from "@mui/material";

type LinkProps = {
  children: JSX.Element
  link: string,
}

function Link({children, link}: LinkProps) {
  const nav = useNavigate();

  return (
    <span style={{cursor: "pointer"}} onClick={() => nav(link)}>{children}</span>
  )
}

type TextLinkProps = {
  text: string,
  link: string,
  color?: string,
}

export function TextLink({text, link, color}: TextLinkProps) {
  return (
    <Link link={link}>
      <Typography style={{color, minWidth: 100}}>{text}</Typography>
    </Link>
  )
}