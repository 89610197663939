import TopBarNavigation from "../components/nav/topbar";
import {Grid, Paper, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useNavigate, useSearchParams} from "react-router-dom";
import {Cell, Pie, PieChart} from "recharts";

const ActionCalls = [{
  title: "Create a company",
  description: "Start managing PHQ questions for a company",
  link: "/clinics",
  action: "create"
}, {
  title: "View Usage Stats",
  description: "View how many users have accepted invites and how many tests have been taken",
  link: "/usage"
}, {
  title: "Invite Patients",
  description: "You need patients to checkin daily? Invite them here.",
  link: "/invite"
}]

const styles = makeStyles({
  ActionItem: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1px 15px 15px",
    backgroundColor: "rgba(100, 100, 100, 0.75) !important",
  }
})


const data = [
  {name: 'Group A', value: 400},
  {name: 'Group B', value: 300},
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;

type labelProps = {
  cx: number,
  cy: number,
  midAngle: number,
  innerRadius: number,
  outerRadius: number,
  percent: number,
  index: number
}

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}: labelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function Dashboard() {
  const classes = styles()
  const nav = useNavigate();
  const [search, setSearch] = useSearchParams()
  return (
    <TopBarNavigation title={"PHQ 365"}>
      <Grid container spacing={2} p={5}>
        <Grid item md={1}/>
        <Grid item md={6}>
          <h1>Start having patients check in daily!</h1>
          <Grid container spacing={2}>
            {ActionCalls.map(action => {
              return (
                <Grid item sm={12}>
                  <Paper className={classes.ActionItem} onClick={() => {
                    nav(action.link)
                    if (action.action) setSearch({action: action.action})
                  }}>
                    <div>
                      <h2>{action.title}</h2>
                      <Typography style={{}}>{action.description}</Typography>
                    </div>
                    <ArrowForwardIosIcon fontSize={"large"} color={"success"}/>
                  </Paper>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Paper style={{
            marginTop: 79,
            padding: "5px 15px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            textAlign: "center"
          }}>
            <h2>Invited Users</h2>
            <PieChart width={600} height={280}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                ))}
              </Pie>
            </PieChart>
          </Paper>
        </Grid>
        <Grid item md={1}/>
      </Grid>
    </TopBarNavigation>
  )
}