import {useEffect, useState} from "react";
import {useProviders} from "../hooks/providers";
import {fetchAuthSession} from "aws-amplify/auth";
import {useRequest} from "../hooks/requests";
import {HTTP_STATUS} from "../tools/enums";
import {Button, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import {useDialog} from "../hooks/simpleDialog";

type props = {
    clinic_id: string
}

type provider = {
    email: string,
    id: string,
    first_name: string,
    last_name: string,
    accepted: boolean,
    clinic_id: string
}

type ResendInviteProps = {
    email: string
    clinic_id: string
}

const styles = makeStyles({
    emailDate: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center"
    },
    status: {
        height: 15,
        width: 15,
        display: "block",
        borderRadius: 8,
    },
    pendingStatus: {
        backgroundColor: "yellow"
    },
    acceptedStatus: {
        backgroundColor: "green"
    },
    providerIcons: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    provider: {
        cursor: "pointer",
    }
})

function ResendInvite({email, clinic_id}: ResendInviteProps) {
    const {post} = useRequest()

    async function ResendInvite() {
        const {tokens} = await fetchAuthSession()
        if (tokens && tokens.accessToken.payload && tokens.accessToken.payload.scope) {
            const resp = await post<any, {
                status: number,
                data: any
            }>(tokens.accessToken.toString(), `admin/clinics/${clinic_id}/providers/invite_provider`, {email});
            if (resp.status === HTTP_STATUS.OK) {
                alert("invite resent")
            }
        } else {
            alert("can't fetch session info")
        }
    }

    return (
        <button onClick={ResendInvite}>Resend Invite</button>
    )
}

type ActiveIconProps = {
    accepted: boolean
}

function ActiveIcon({accepted}: ActiveIconProps) {
    const style = styles();
    return <div className={`${style.status} ${accepted ? style.acceptedStatus : style.pendingStatus}`}/>
}

function Provider({email, accepted, clinic_id, id}: provider) {
    const style = styles();
    const {setOpen, setActions, setTitle} = useDialog();
    const req = useRequest()
    const [loggedUser, setLoggedUser] = useState("")

    useEffect(() => {
        async function load() {
            const {tokens} = await fetchAuthSession()

            if (tokens && tokens.accessToken) {
                setLoggedUser(tokens.accessToken.payload.id as string)
            }
        }

        load()
    }, [])

    async function removeInvite() {
        const {tokens} = await fetchAuthSession()
        if (tokens && tokens.accessToken.payload && tokens.accessToken.payload.scope) {
            const res = await req.delete(tokens.accessToken.toString(), "admin/clinics/" + clinic_id + "/providers/" + id + "/invited")
            if (res.status !== HTTP_STATUS.NO_CONTENT) {
                alert("couldn't delete")
                return
            }
        }

    }

    async function resendInvite() {
        const {tokens} = await fetchAuthSession()
        if (tokens && tokens.accessToken.payload && tokens.accessToken.payload.scope) {
            const res = await req.post(tokens.accessToken.toString(), "admin/clinics/" + clinic_id + "/providers/invites", {email});
            if (res.status !== HTTP_STATUS.OK) {
                alert("couldn't resend invite")
                return
            }
        }
    }

    function performActionProvider() {
        setTitle("Manage Provider " + email)
        setActions([<Button color={"error"} variant={"contained"} onClick={() => removeInvite()}>Remove</Button>,
            <Button onClick={() => resendInvite()} variant={"contained"}>Resend Invite</Button>])
        setOpen(true)
    }

    return (
        <Grid className={style.provider} container spacing={5} onClick={performActionProvider}>
            <Grid item sm={2} md={2} lg={2} xl={2}>
                <div className={style.providerIcons}>
                    <LocalHospitalIcon fontSize={"small"}/>
                    <ActiveIcon accepted={accepted}/>
                </div>
            </Grid>
            <Grid item sm={10} md={10} lg={10} xl={10}>
                <div className={style.emailDate}>
                    <span>{email} {(id === loggedUser) && "(you)"}</span>
                    {/*<span>Expires On: {new Date(expires).toLocaleDateString()}</span>*/}
                </div>
            </Grid>
        </Grid>
    )
}

export function ShowInvitedProviders({clinic_id}: props) {
    const {showInvited} = useProviders()
    const [invited, setInvited] = useState<provider[]>([])
    useEffect(() => {
        async function load() {
            const {tokens} = await fetchAuthSession()
            if (tokens && tokens.accessToken.payload && tokens.accessToken.payload.scope) {
                const invited = await showInvited(tokens.accessToken.toString(), clinic_id);
//                setInvited([...invited])
            }
        }

        load();
    }, []);

    return (
        <>
            {invited.map((provider, index: number) => <Provider key={index} {...provider} />)}
        </>
    )
}

export default function ViewProviders({clinic_id}: props) {
    const {showProviders, providers} = useProviders()

    useEffect(() => {
        async function load() {
            const {tokens} = await fetchAuthSession()
            if (tokens && tokens.accessToken.payload && tokens.accessToken.payload.scope) {
                await showProviders(tokens.accessToken.toString(), clinic_id)
            }

        }

        load();
    }, []);

    return (
        <>
            {providers.managers.map((provider, index) => <Provider key={index} clinic_id={clinic_id} {...provider} />)}
        </>
    )
}