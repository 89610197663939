import {useState} from 'react';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddHomeIcon from '@mui/icons-material/AddHome';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {TextField} from "@mui/material";

type callbackProps = {
    callback: (text: string) => void
    onFocus?: (text: string) => void
    onBlur?: (text: string) => void
}

type Keypress = {
    keypress?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => Promise<void> | void;
}

type OnChange = {
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => Promise<void> | void;
}

type fieldErrorProps = {
    error?: boolean
    msg?: string
}

type validationProps = {
    email?: boolean
    pass?: boolean
}

type props = {
    children: JSX.Element,
    label: string,
    success?: boolean,
    endIcon?: JSX.Element,
    defaultValue?: string
} & callbackProps & validationProps & fieldErrorProps & Keypress;

export function Meta() {
    return (
        <FormControl variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment">
                First Name
            </InputLabel>
            <Input id="input-with-icon-adornment"/>
        </FormControl>
    )
}

function InputWithIcon({
                           children,
                           label,
                           callback,
                           email,
                           error,
                           msg,
                           onFocus,
                           onBlur,
                           success,
                           pass,
                           keypress,
                           endIcon, defaultValue
                       }: props) {
    const [text, setText] = useState<string | null>(defaultValue || "")

    const inputConfig: { [key: string]: string } = {}

    if (email) {
        inputConfig.type = "email"
    }

    if (pass) {
        inputConfig.type = "password"
    }

    if (success) {
        inputConfig.color = 'success'
    }

    function onChange(text: string) {
        setText(text)
        callback(text)
    }

    return (
        <FormControl variant="standard" style={{width: "100%"}}>
            <InputLabel>
                {label}
            </InputLabel>
            <Input
                {...inputConfig}
                fullWidth={true}
                error={error}
                onKeyDown={keypress}
                value={text}
                onFocus={onFocus ? (event) => onFocus(event.currentTarget.value) : onFocus}
                onBlur={onBlur ? (event) => onBlur(event.currentTarget.value) : onBlur}
                onChange={(e) => onChange(e.currentTarget.value)}
                startAdornment={
                    <InputAdornment position="start">
                        {children}
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        {endIcon}
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}

type ProviderEmailProps = {
    success?: boolean
} & callbackProps & fieldErrorProps & Keypress;

export function ProviderEmail({callback, error, msg, onFocus, onBlur, success, keypress}: ProviderEmailProps) {
    return (
        <InputWithIcon
            label='Provider Email'
            success={success}
            keypress={keypress}
            error={error}
            msg={msg}
            email={true}
            callback={callback}
            onBlur={onBlur}
            onFocus={onFocus}
        >
            <GroupAddIcon/>
        </InputWithIcon>
    )
}

type UsernameProps = {
    success?: boolean,
    defaultValue?: string
} & callbackProps & fieldErrorProps & Keypress;

type ClinicProps = {
    success?: boolean
} & callbackProps & fieldErrorProps & Keypress;

export function ClinicName({callback, error, msg, onFocus, onBlur, success, keypress}: ClinicProps) {
    return (
        <InputWithIcon
            label='Clinic Name'
            success={success}
            keypress={keypress}
            error={error}
            msg={msg}
            email={true}
            callback={callback}
            onBlur={onBlur}
            onFocus={onFocus}
        >
            <AddHomeIcon/>
        </InputWithIcon>
    )
}

export function Username({callback, error, msg, onFocus, onBlur, success, keypress, defaultValue}: UsernameProps) {
    return (
        <InputWithIcon
            label='Username'
            success={success}
            keypress={keypress}
            error={error}
            defaultValue={defaultValue}
            msg={msg}
            email={true}
            callback={callback}
            onBlur={onBlur}
            onFocus={onFocus}
        >
            <AccountCircle/>
        </InputWithIcon>
    )
}

type PasswordProps = {
    confirm?: boolean
} & callbackProps & fieldErrorProps & Keypress;

export function Password({callback, confirm, error, msg, onBlur, onFocus, keypress}: PasswordProps) {
    const [hidePass, setHidePass] = useState(true)

    return (
        <InputWithIcon
            keypress={keypress}
            onBlur={onBlur}
            onFocus={onFocus}
            label={(confirm ? 'Confirm ' : '') + 'Password'}
            callback={callback}
            error={error}
            pass={hidePass}
            endIcon={
                <span style={{cursor: "pointer"}} onClick={() => setHidePass(!hidePass)}>
                {hidePass ? <VisibilityIcon fontSize={"small"}/> : <VisibilityOffIcon fontSize={"small"}/>}
            </span>
            }
        >
            <LockIcon/>
        </InputWithIcon>
    )
}

type RegularTextFieldProps = {
    label: string,
    name: string,
} & OnChange & fieldErrorProps

function RegularTextField({label, name, onChange, error}: RegularTextFieldProps) {
    return (
        <TextField label={label} name={name} fullWidth={true} onChange={onChange} error={error}/>
    )
}

type NameProps = {} & OnChange & fieldErrorProps

export function FirstName({onChange, error}: NameProps) {
    return <RegularTextField label={"First Name"} name={"firstName"} onChange={onChange} error={error}/>
}

export function LastName({onChange, error}: NameProps) {
    return <RegularTextField label={"Last Name"} name={"lastName"} onChange={onChange} error={error}/>
}