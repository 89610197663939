import {Avatar, Box, Divider, IconButton, ListItemIcon, MenuItem, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import {TextLink} from "./link";
import MyMenu from "./menu";
import {Logout, PersonAdd, Settings} from "@mui/icons-material";
import {fetchAuthSession, signOut} from "aws-amplify/auth";
import {useProvideUserData} from "../../hooks/users";

const styles = makeStyles({
    topbarContainer: {
        display: "flex",
        position: "absolute",
        top: 0,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 5,
        backgroundColor: "rgb(0, 0, 0)",
        width: "100%"
    },
    navItem: {
        display: "flex",
        '&:last-child': {
            marginRight: 15,
        }
    },
    flex: {
        display: "flex"
    },
    center: {
        alignItems: "center",
    },
    lastElRight: {
        justifyContent: "space-between"
    },
    width400: {
        minWidth: 400
    }
})

type TopBarNavigationProps = {
    title: string,
    children: JSX.Element
}

type Profile = {
    first_name: string
}

function multipleClasses(classes: string[]): string {
    return classes.join(" ")
}

export default function TopBarNavigation({title, children}: TopBarNavigationProps) {
    const classes = styles()
    const nav = useNavigate()
    const [open, setOpen] = useState(false)
    const [canViewClinics, setViewClinics] = useState(false);
    const {profile} = useProvideUserData()

    const [el, setEl] = useState<HTMLElement>()

    function handleClose() {
        setOpen(!open)
    }

    useEffect(() => {
        async function load() {
            const {tokens} = await fetchAuthSession()
            if (tokens?.accessToken?.payload?.scope) {
                setViewClinics(tokens?.accessToken?.payload?.scope.indexOf("view_clinics") > -1)
            }
        }

        load();
    }, [])

    async function logout() {
        await signOut({global: true});
        localStorage.clear();
        sessionStorage.clear()
        nav("/")
    }

    function Home() {
        nav("/dashboard")
    }

    return (
        <>
            <Box className={classes.topbarContainer}>
                <Box
                    className={multipleClasses([classes.navItem, classes.flex, classes.width400, classes.lastElRight])}>
                    <Box style={{marginLeft: 15}}><h3 style={{color: "white", cursor: "pointer"}}
                                                      onClick={Home}>{title}</h3>
                    </Box>
                    <Box className={multipleClasses([classes.center, classes.flex])}>
                        {canViewClinics && <TextLink link={"/clinics"} text={"Clinics"} color={"white"}/>}
                        <TextLink link={"/usage"} text={"Usage"} color={"white"}/>
                        <TextLink link={"/pricing"} text={"Plans"} color={"white"}/>
                    </Box>
                </Box>
                <Box className={classes.navItem}>
                    <Tooltip title="Account settings">
                        <IconButton
                            onClick={(event) => {
                                setOpen(!open);
                                setEl(event.currentTarget)
                            }}
                            size="small"
                            sx={{ml: 2}}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{
                                width: 32,
                                height: 32
                            }}>{profile && profile.first_name ? profile.first_name[0] : ""}</Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
                <MyMenu anchor={el!} open={open} setOpen={setOpen}>
                    <MenuItem onClick={handleClose}>
                        <Avatar/> <TextLink link={"/profile"} text={"Profile"}/>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Avatar/> <TextLink link={"/account"} text={"My Account"}/>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <PersonAdd fontSize="small"/>
                        </ListItemIcon>
                        <TextLink link={"/invite"} text={"Invite someone"}/>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Settings fontSize="small"/>
                        </ListItemIcon>
                        <TextLink link={"/settings"} text={"Settings"}/>
                    </MenuItem>
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <Logout fontSize="small"/>
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </MyMenu>
            </Box>
            <div style={{marginTop: 70, width: "100%", padding: 20}}>
                {children}
            </div>
        </>
    )
}