import TopBarNavigation from "../components/nav/topbar";
import {useParams, useSearchParams} from "react-router-dom";
import {useDialog} from "../hooks/simpleDialog";
import {useEffect, useState} from "react";
import {ClinicForm, CreateClinic, InviteProvider, InviteProviderForm} from "../components/auth/form";
import {
    Accordion, AccordionDetails, AccordionSummary, Button, Grid,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useClinicData} from "../hooks/clinics";
import {fetchAuthSession} from "aws-amplify/auth";
import {validate} from "../models/auth";
import {InviteProviderSchema} from "../models/providers";
import {makeStyles} from "@mui/styles";
import ViewProviders, {ShowInvitedProviders} from "../components/view_providers";
import clinicIcon from '../imgs/clinicicon.png';

type ClinicProvidersProps = {
    id: string
}

const styles = makeStyles({
    mngmntBtn: {
        cursor: "pointer",
        height: 25,
        marginLeft: 5,
        padding: 5,
        borderRadius: 7,
        borderBottomColor: "orange",
        backgroundColor: "orange"
    },
    headerRow: {
        display: "flex",
        alignItems: "center"
    },
    dropDownHader: {
        display: "flex",
        justifyContent: "space-between"
    },
    dropdowns: {
        backgroundColor: "#e9f3f5",
        padding: 20,
        borderRadius: 20,
    },
    clinicIcon: {
        height: 50,
        width: 50,
        marginRight: 10
    },
    clinicHeader: {
        display: "flex",
        alignItems: "center"
    },
    dropdownIcon: {
        color: "#6D6D6D"
    },
    inviteButton: {
        backgroundColor: "rgba(0, 109, 209, 0.24)",
        letterSpacing: "-2%",
        padding: 8,
        color: "#52577E",
        border: 0.25,
        borderColor: "rgba(82, 87, 126, 0.14)",
        fontSize: 20,
        borderRadius: 5,
        height: 42,
        cursor: "pointer"
    }
})


type setSearchProps = { invite: string }

type InviteProvidersProps = {
    id: string,
    setSearch: (props: setSearchProps) => void
}

function InviteProviders({id, setSearch}: InviteProvidersProps) {
    const [canInvite, setCanInvite] = useState(false);

    useEffect(() => {
        async function prep() {
            const {tokens} = await fetchAuthSession();
            if (tokens && tokens.accessToken.payload && tokens.accessToken.payload.scope) {
                setCanInvite(tokens?.accessToken?.payload?.scope.toString()?.indexOf(`${id}.invite_providers`) > -1)
            }
        }

        prep();
    }, [])

    return (
        <>
            {canInvite && <Button
                onClick={() => setSearch({invite: id})}>Invite</Button>}
        </>
    )
}

function InvitedProviders({id}: ClinicProvidersProps) {
    return <ShowInvitedProviders clinic_id={id}/>
}

function ClinicProviders({id}: ClinicProvidersProps) {
    const [_, setSearch] = useSearchParams()
    const style = styles()
    useEffect(() => {
        console.log("mounted")

        return () => console.log("unmounted")
    }, [])

    return (
        <>
            <ViewProviders clinic_id={id}/>
        </>
    )
}

export default function Companies() {
    const [search, setSearchParams] = useSearchParams()
    const {createClinic, getAllMyClinics, clinics} = useClinicData()
    const [errors, setErrors] = useState<{ [key: string]: string }>({})
    const style = styles();

    const {setOpen, setActions, setContent, setTitle} = useDialog()
    const [form, setForm] = useState<ClinicForm>({
        name: ""
    })

    const [inviteForm, setInviteForm] = useState<InviteProviderForm>({
        email: ""
    })

    const [filter, setFiltered] = useState(false);

    async function Submit(form: ClinicForm) {
        const {tokens} = await fetchAuthSession()
        await createClinic(form, tokens?.accessToken.toString()!)
    }

    useEffect(() => {
        function create() {
            setOpen(true)
            setTitle("Create a Clinic")
            setContent(<CreateClinic Submit={Submit} form={form} setForm={setForm}/>)
            setActions([<Button variant={"contained"}>Create</Button>])
        }

        if (search.get("action")) create()
        if (form.name) setContent(<CreateClinic Submit={Submit} form={form} setForm={setForm}/>)
    }, [search.get("action"), form.name])

    async function SubmitInvite(form: InviteProviderForm) {
        const errors = validate(InviteProviderSchema, form)
        if (Object.keys(errors).length > 0) {
            setErrors({...errors})
            return
        }
        setErrors({})
        const {tokens} = await fetchAuthSession()
        const response = await fetch("https://api.phq365.com/admin/clinics/" + search.get("invite") + "/providers/invites", {
            method: "post",
            body: JSON.stringify(form),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + tokens?.accessToken.toString(),
            }
        })
        console.log(response.status)
    }

    useEffect(() => {
        function showInviteScreen() {
            if (search.get("invite")) {
                const c = clinics.manages.find((clinic) => {
                    return clinic.id === search.get("invite")
                })
                console.log(errors, "show invite screen");
                setOpen(true)
                setTitle("Invite a Provider to Clinic: " + c!.name)
                setContent(<InviteProvider Submit={SubmitInvite} form={inviteForm} setForm={setInviteForm}
                                           passedErrors={errors}/>)
                setActions([<Button onClick={(e) => SubmitInvite(inviteForm)} variant={"contained"}>Create</Button>])
            }
        }

        if (clinics.manages.length > 0) showInviteScreen()
    }, [search.get("invite"), clinics.manages.length, errors, inviteForm])

    useEffect(() => {
        async function load() {
            const {tokens} = await fetchAuthSession()
            await getAllMyClinics(tokens?.accessToken.toString()!);
        }

        load();
    }, [])

    function filterManaged() {
        setFiltered(!filter);
    }

    return (
        <TopBarNavigation title={"PHQ 365"}>
            <>
                <div className={style.headerRow}>
                    <h3>Here are your Clinic's</h3>
                    <button onClick={() => filterManaged()} className={style.mngmntBtn}>Managed Clinics</button>
                </div>
                <hr />
                <Grid container spacing={2} style={{backgroundColor: "#fafafa", padding: 5}}>
                    {clinics.manages.map((clinic) => <ClinicBadge clinic={clinic} />)}
                    {!filter && clinics.practices_at.map((clinic) => <ClinicBadge clinic={clinic} />)}
                </Grid>
            </>
        </TopBarNavigation>
    )
}

type ClinicBadgeProps = {
    clinic: {
        id: string,
        name: string,
    }
}

function ClinicBadge({clinic}: ClinicBadgeProps) {
    const style = styles();
    const [search, setSearchParams] = useSearchParams()
    return (
        <Grid item xs={12} sm={6} md={4} lg={4} style={{backgroundColor: "white", padding: 10}}>
                                <div className={style.clinicHeader} style={{justifyContent: "space-between"}}>
                                    <div className={style.clinicHeader}>
                                        <img alt={"clinic icon"} src={clinicIcon} className={style.clinicIcon}/>
                                        <h4>{clinic.name}</h4>
                                    </div>
                                    <div>
                                        <button onClick={() => setSearchParams({invite: clinic.id})}
                                                className={style.inviteButton}>Invite
                                        </button>
                                    </div>

                                </div>
                                <hr style={{}}/>
                                <div className={style.dropdowns}>
                                    <Accordion style={{backgroundColor: "transparent", borderBottom: "none"}}
                                               slotProps={{transition: {unmountOnExit: true}}}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon className={style.dropdownIcon}/>}>
                                            <h5>Active Providers</h5>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ClinicProviders id={clinic.id}/>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion style={{backgroundColor: "transparent"}}
                                               slotProps={{transition: {unmountOnExit: true}}}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon className={style.dropdownIcon}/>}>
                                            <h5>Pending Invites</h5>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <InvitedProviders id={clinic.id}/>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Grid>
    )
}