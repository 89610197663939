import {makeStyles} from "@mui/styles";
import {CircularProgress} from "@mui/material";
import {useLoading} from "../hooks/loading";

type Props = {
  children: JSX.Element,
}

const styles = makeStyles({
  loadingScreen: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "block",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 9998,
  },
  icon: {
    position: "absolute",
    left: "45%",
    top: "45%",
    zIndex: 9999,
  }
})

export default function Loading({children}: Props) {
  const style = styles()
  const {loading} = useLoading()
  return (
    <div>
      <div className={style.loadingScreen} style={{display: loading ? "block" : "none"}}/>
      <CircularProgress size={100} className={style.icon} style={{display: loading ? "block" : "none"}}/>
      {children}
    </div>
  )
}