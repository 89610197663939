import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {fetchAuthSession, getCurrentUser, signUp} from "aws-amplify/auth";
import {useRequest} from "../../hooks/requests";
import {makeStyles} from "@mui/styles";
import {AcceptInvite, AcceptInviteForm} from "./form";
import {Box, Button, Paper} from "@mui/material";
import TopBarNavigation from "../nav/topbar";
import Lockdown from "./lockdown";
import {HTTP_STATUS} from "../../tools/enums";

const styles = makeStyles({
    signedOutFormContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    signedInComp: {
        height: 200,
        width: 400,
        textAlign: "center"
    }
})

export function InviteChecker() {
    const {clinic_id, token} = useParams();
    const [signedIn, setSignedIn] = useState(false)
    useEffect(() => {
        async function check() {
            try {
                const profile = await getCurrentUser()
                console.log(profile);
                setSignedIn(true);
//        render the joining with the logged in user
            } catch (error) {
                setSignedIn(false)
//        render the joining with a signedout user.
            }
        }

        check()
    }, []);

    useEffect(() => {
        async function checkToken() {
//      const response = await get<{}, { status: number, data: {} }>("", "join/accept?token=" + token, false)
//      console.log(response)
        }

        checkToken();
    }, [])

    console.log(clinic_id, token)

    return (
        <>
            {signedIn && <Lockdown><SignedInAcceptance/></Lockdown>}
            {!signedIn && <SignedOutAcceptance/>}
        </>
    )
}

type resolve = {
    email: string,
    clinic_id: string,
    active: boolean
}

function SignedOutAcceptance() {
    const [clinic_name, set_clinic_name] = useState("");
    const [email, setEmail] = useState("")
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(true);
    const {token, clinic_id} = useParams()
    const {get, post} = useRequest()
    const navigate = useNavigate();
    useEffect(() => {
        async function resolve() {
            setLoading(true);
            const resp = await get<resolve, {
                status: number,
                data: resolve,
            }>("", "join/resolve?token=" + token, false)
            set_clinic_name(resp.data.clinic_id);
            setEmail(resp.data.email)
            setActive(resp.data.active)
            setLoading(false)
        }

        resolve();
    }, [])

    const [form, setForm] = useState<AcceptInviteForm>({
        first_name: "",
        last_name: "",
        pass: "",
        confirmPassword: ""
    })

    const [errors, setErrors] = useState<{ [key: string]: string }>({})

    const style = styles();

    async function Submit({first_name, last_name, pass}: AcceptInviteForm) {

        const cognito = signUp({
            username: email,
            password: pass,
            options: {
                userAttributes: {
                    email: email,
                }
            }
        })

        const response = await cognito;
        const payload = {first_name, last_name, clinic_id, auth_id: response.userId!};
        await post("", "join/accept?token=" + token, payload, false, false);

    }

    return (<div className={style.signedOutFormContainer} style={{height: window.innerHeight}}>
        {(!active && !loading) &&
            <AcceptInvite Submit={Submit} form={form} setForm={setForm} errors={errors} setErrors={setErrors}
                          clinic_name={clinic_name} email={email}/>}
        {(active && !loading) && <Box style={{height: 50, padding: 5}} component={Paper}>
            <div>To accept invite to Clinic: {clinic_name}</div>
            <Button
                onClick={() => navigate(`/?email=${email}&redirect=${encodeURI("/clinics/" + clinic_id + "/join/" + token)}`)}>Login
                as {email}</Button></Box>}
    </div>);
}

function SignedInAcceptance() {
    const style = styles();
    const {token, clinic_id} = useParams()
    const {get} = useRequest()
    const [loading, setLoading] = useState(true)
    const [clinic_name, set_clinic_name] = useState("")
    const [can_join, set_can_join] = useState(false);
    useEffect(() => {
        async function resolve() {
            setLoading(true);
            const resp = await get<resolve, {
                status: number,
                data: resolve,
            }>("", "join/resolve?token=" + token, false)
            set_clinic_name(resp.data.clinic_id);


            const {tokens} = await fetchAuthSession()
            if (tokens && tokens?.accessToken) {
                const resp = await get<{ [key: string]: string }, {
                    status: number,
                    data: { [key: string]: string }
                }>(tokens.accessToken.toString(), "admin/clinics/" + clinic_id + "/belongs", true, false);
                set_can_join(resp.status === HTTP_STATUS.NO_CONTENT)
            }

            setLoading(false)
        }

        resolve();
    }, [])

    return (
        <TopBarNavigation title={"PHQ 365"}>
            <div className={style.signedOutFormContainer} style={{height: window.innerHeight - 120}}>
                <Box className={style.signedInComp} component={Paper}>
                    {(can_join && !loading) ? (<>
                        <h3>Clinic: {clinic_name}</h3>
                        <Button>Join</Button></>) : !loading ? (<>Already apart of</>) : (<>Loading...</>)}
                </Box>
            </div>
        </TopBarNavigation>
    )
}