import Joi from 'joi'; 


export function validate(schema: Joi.ObjectSchema<any>, value: any)  {
    const result = schema.validate(value);
    const error = result.error;
    let errors: {[key:string]: string} = {}
    if (error instanceof Joi.ValidationError) {
        for (let index = 0; index < error.details.length; index++) {
            const err = error.details[index];
            const field = err.path[0]
            const msg = err.message;
            errors = {...errors, [field]:msg}
        }
    }

    return errors
}

export const RegisterAuthSchema = Joi.object({
    username: Joi.string().required().email({tlds: false}), 
    password: Joi.string().required(), 
    confirmPassword: Joi.ref('password')
})

export const LoginAuthSchema = Joi.object({
    username: Joi.string().required().email({tlds: false}),
    password: Joi.string().required(),
})