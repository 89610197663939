import {createContext, useContext, useState} from "react";

type LoadingContext = {
  loading: boolean,
  setLoading: (load: boolean) => void
}

export const LoadingCtx = createContext<LoadingContext>({
  loading: false,
  setLoading: (load: boolean) => {
  }
})

export const ProvideLoading = ({children}: { children: JSX.Element }) => {
  const ctx = Provider();
  return <LoadingCtx.Provider value={ctx}>{children}</LoadingCtx.Provider>
}

export const useLoading = () => {
  return useContext(LoadingCtx);
}

const Provider = () => {
  const [loading, setLoading] = useState<boolean>(false)
  return {
    loading,
    setLoading
  }
}