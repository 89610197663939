import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {CreateUser} from "./auth/form";
import {useDialog} from "../hooks/simpleDialog";

type DialogProps = {
  children: JSX.Element,
  open: boolean,
  setOpen: (state: boolean) => void
  content: JSX.Element,
  actions: JSX.Element[],
  title: string,
}

type CreateUserForm = {
  firstName: string,
  lastName: string
} & { [key: string]: any }

type CreateUserProps = {
  setForm: (form: CreateUserForm) => void
  form: CreateUserForm
  errors: { [key: string]: any }
}

export function CreateUserFormContent({form, setForm, errors}: CreateUserProps) {
  return (
    <CreateUser errors={errors} form={form} setForm={setForm}/>
  )
}

type CreateUserFormActionsProps = {
  action: () => void
}

export function CreateUserFormActions(props: CreateUserFormActionsProps) {
  return (
    <Button type={"submit"} onClick={() => props.action()}>
      Create Profile
    </Button>
  )
}

export default function SimpleDialog({children, open, setOpen, content, actions, title}: DialogProps) {
  const {setTitle, setContent, setActions} = useDialog()

  function handleClose() {
    setOpen(!open)
    setActions([]);
    setContent(<></>)
    setTitle("")
  }

  return (
    <>
      {children}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent style={{minHeight: 200, paddingTop: 20, minWidth: 400}}>
          {content}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    </>
  )
} 