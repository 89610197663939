import {createContext, useContext, useState} from "react";
import {HTTP_STATUS} from "../tools/enums";
import {useRequest} from "./requests";

type Provider = {
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    accepted: boolean,
}

type AllProviders = {
    managers: Provider[],
    providers: Provider[],
}

type ProvidersContext = {
    providers: AllProviders
    invited: Provider[],
    showProviders: (token: string, clinic_id: string) => Promise<void>
    showInvited: (token: string, clinic_id: string) => Promise<Provider[]>
}

export const ProvidersCtx = createContext<ProvidersContext>({
    providers: {
        managers: [], providers: []
    },
    invited: [],
    showProviders: async (token: string, clinic_id: string) => {

    },
    showInvited: async (token: string, clinic_id: string) => {
        return [] as Provider[]
    }
})

export const ProvideProviders = ({children}: { children: JSX.Element }) => {
    const ctx = Provider();
    return <ProvidersCtx.Provider value={ctx}>{children}</ProvidersCtx.Provider>
}

export const useProviders = () => {
    return useContext(ProvidersCtx);
}

type get_providers = {
    status: number,
    data: AllProviders
}

const Provider = () => {
    const [providers, setProviders] = useState<AllProviders>({
        managers: [],
        providers: [],
    })
    const [invited, setInvited] = useState<Provider[]>([])
    const {get} = useRequest()

    async function showInvited(token: string, clinic_id: string): Promise<Provider[]> {
        const response = await get<Provider[], {status: number, data: Provider[]}>(token, `admin/clinics/${clinic_id}/providers/invites`, true)
        if (response.status === HTTP_STATUS.OK) {
            return response.data as Provider[]
        }

        return []
    }

    async function showProviders(token: string, clinic_id: string) {
        const response = await get<AllProviders, get_providers>(token, `admin/clinics/${clinic_id}/providers`, true)
        if (response.status === HTTP_STATUS.OK) {
            setProviders(response.data)
        }
    }

    return {
        providers,
        invited,
        showProviders,
        showInvited
    }
}