import {ProvideTiers, useProvideTiers} from "../hooks/pricing";
import {useEffect} from "react";
import {fetchAuthSession} from "aws-amplify/auth";
import {useLoading} from "../hooks/loading";
import {Button, Grid, Paper} from "@mui/material";
import {makeStyles} from "@mui/styles";
import TopBarNavigation from "../components/nav/topbar";

type Tier = {
  name: string,
  id: string,
  default_price: {
    unit_amount: number,
  }
}

type TierProps = {
  totalTiers: number,
  currentTier: number,
  tier: Tier,
}

const styles = makeStyles({
  tierHeader: {
    textAlign: "center"
  }
})

function Tier({totalTiers, tier}: TierProps) {
  const style = styles()
  let width: number = parseInt((12 / totalTiers).toFixed(0))
  if (totalTiers === 1) {
    width = 4;
  }

  return (
    <Grid item sm={12} md={width} style={{marginTop: 70}}>
      <Paper>
        <h3 className={style.tierHeader}>{tier.name}</h3>
        <h4 className={style.tierHeader}>${(tier.default_price.unit_amount / 100).toFixed(2)}</h4>
        <Button>Select Plan</Button>
      </Paper>
    </Grid>

  )
}

function DisplayTiers() {
  const {getTiers, tiers} = useProvideTiers()
  const {setLoading} = useLoading()
  useEffect(() => {
    async function load() {
      setLoading(true)
      const {tokens} = await fetchAuthSession()
      await getTiers(tokens?.accessToken.toString()!)
      setLoading(false)
    }

    load();
  }, [])

  return (
    <Grid container spacing={2}>
      {tiers.length === 1 && (<Grid item sm={0} md={4}/>)}
      {tiers.map((tier, index) => {
        return (<Tier currentTier={index} tier={tier} totalTiers={tiers.length}/>)
      })}
      {tiers.length === 1 && (<Grid item sm={0} md={4}/>)}
    </Grid>
  )
}

export default function PricingPage() {
  return (
    <TopBarNavigation title={"PHQ 365"}>
      <ProvideTiers>
        <DisplayTiers/>
      </ProvideTiers>
    </TopBarNavigation>
  )
}