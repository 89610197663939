import {createContext, useContext, useState} from "react";
import Fetch from "../tools/fetch";
import {HTTP_STATUS} from "../tools/enums";

type Name = {
  first_name: string;
  last_name: string;
}

type Profile = {
  email: string;
  id: string;
  status: number;
} & Name

type UserContext = {
  profile: Profile,
  getProfile: (token: string) => Promise<void>,
  createProfile: (basicProfile: Name, token: string) => Promise<boolean>,
  loading: boolean
}

export const UserContext = createContext<UserContext>({
  profile: {
    first_name: "",
    last_name: "",
    email: "",
    id: "",
    status: -1,
  },
  getProfile: async () => {
    console.log("this")
  },
  createProfile: async (basicProfile: Name, token: string) => {
    return false
  },
  loading: false,
})

export const ProvideUserData = ({children}: { children: JSX.Element }) => {
  const ctx = Provider();
  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>
}

export const useProvideUserData = () => {
  return useContext(UserContext);
}

const Provider = () => {
  const p = sessionStorage.getItem("profile")
  const [profile, setProfile] = useState<Profile>(!!p ? JSON.parse(p) : {
    first_name: "",
    last_name: "",
    id: "",
    email: "",
    status: -1
  });

  const [loading, setLoading] = useState<boolean>(false)

  async function getProfile(token: string) {
    const f = new Fetch("https://api.phq365.com", 443)
    const response = await f.Token(token).Get().Path("admin/owners/me").Receive<Profile>();
    console.log(response)
    setProfile({...(response as { data: Profile }).data, status: response.status})
  }

  async function createProfile(basicProfile: Name, token: string) {
    setLoading(true)
    const f = new Fetch("https://api.phq365.com", 443)
    const response = await f.Token(token).Post().Path("admin/owners").Body(basicProfile).Receive<Profile>();
    setProfile({...(response as { data: Profile }).data})
    setLoading(false)
    return response.status === HTTP_STATUS.CREATED;
  }

  return {
    profile,
    getProfile,
    createProfile,
    loading
  }
}